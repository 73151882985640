<template>
  <layout-horizontal>

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <app-navbar-horizontal-layout-brand />
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <div slot="footer">
      <p class="mb-0">
        &copy; {{ new Date().getFullYear() }} <a
          href="https://www.bioforcetech.com/"
          target="_blank"
        >Bioforcetech</a>, all rights reserved <feather-icon
          size="16"
          icon="BookIcon"
          class="link ml-50"
          @click="openDocumentation"
        />
      </p>
    </div>
  </layout-horizontal>
</template>

<script>
import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal.vue'
import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    LayoutHorizontal,
    Navbar,
    AppNavbarHorizontalLayoutBrand,
  },
  methods: {
    openDocumentation() {
      let anchor = '';
      switch (this.$route.name) {
        case "projects":
          anchor = 'using-the-cloud.html#projects';
          break;
        case "devices":
          anchor = 'using-the-cloud.html#devices';
          break;
        case "users":
          anchor = 'using-the-cloud.html#users';
          break;
        case "alarms":
          anchor = 'using-the-cloud.html#alarms';
          break;
        case "backups":
          anchor = 'using-the-cloud.html#backups';
          break;
        case "blackboxes":
          anchor = 'using-the-cloud.html#blackbox';
          break;
        case "reports":
        case "report-sections":
          anchor = 'report-configuration';
          break;
        case "settings":
          anchor = 'settings';
          break;
        default:
          window.open('https://docs.v1.plexus-automation.com/en/cloud/', '_blank', undefined, true).focus();
          return;
      }
      window.open(`https://docs.v1.plexus-automation.com/en/cloud/${anchor}`, '_blank', undefined, true).focus();
    },
  }
}
</script>

<style lang="scss" scoped>
.link {
  cursor: pointer;
}
</style>